import React, { Suspense } from "react";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { ReactPlugin } from "@microsoft/applicationinsights-react-js";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import TrackOrderFormPage from "./pages/TrackOrderFormPage";
import TrackingMultipleFormPage from "./pages/TrackMultipleFormPage";
import NotFoundPage from "./pages/NotFoundPage";
import ErrorPage from "./pages/ErrorPage";
import LoadingAnimation from "./components/LoadingAnimation";
import Layout from "./Layout";
import OrderDetailsPage from "./pages/OrderDetailsPage";
import CancelOrderModal from "./modals/CancelOrderModal";
import {
  FetchOrderData,
  NotFoundError,
  UnknownError,
  HttpError,
  FetchMultipleOrderDatas,
  FetchPODDetailsByReference,
} from "./functions/ApiCalls";
import MultipleOrderDetailsPage from "./pages/MultipleOrderDetailsPage";
import "./scss/custom.scss";
import SMSModal from "./modals/SMSModal";
import PODOnDemandModal from "./modals/PODOnDemandModal";
import TrackDriverModal from "./modals/TrackDriverModal";
import DeliveryTimeModal from "./modals/DeliveryTimeModal";
import DeliveryInstructionsModal from "./modals/DeliveryInstructionModal";
import PODECommerceModal from "./modals/PODECommerceModal";
import PODModal from "./modals/PODModal";
import i18n from "./i18n";
import { I18nextProvider } from "react-i18next";

var reactPlugin = new ReactPlugin();
var appInsights = new ApplicationInsights({
  config: {
    instrumentationKey:
      process.env.REACT_APP_APPLICATION_INSIGHTS_INSTRUMENTATION_KEY,
    enableAutoRouteTracking: true,
    extensions: [reactPlugin],
  },
});
appInsights.loadAppInsights();

async function orderLoader({ params: { trackingNumber } }) {
  const response = await FetchOrderData(trackingNumber);

  if (response instanceof UnknownError) {
    throw new Error("An unknown error occurred");
  }
  if (response instanceof NotFoundError) {
    throw new Error(`Could not find order: ${trackingNumber}`);
  }

  if (response instanceof HttpError) {
    throw new Error(`Unable to contact API. Error Code: ${response.errorCode}`);
  }

  return {
    order: response,
    trackingNumber: response.orderDetails.appleTrackingNumber,
  };
}

async function podByReference({ params: { referenceNumber } }) {
  const response = await FetchOrderData(referenceNumber);

  if (response instanceof UnknownError) {
    throw new Error("An unknown error occurred");
  }
  if (response instanceof NotFoundError) {
    throw new Error(`Could not find reference: ${referenceNumber}`);
  }

  if (response instanceof HttpError) {
    throw new Error(`Unable to contact API. Error Code: ${response.errorCode}`);
  }

  return {
    order: response,
    trackingNumber: response.orderDetails.appleTrackingNumber,
  };
}

async function multipleOrderLoader({ params: { trackingNumbers } }) {
  const response = await FetchMultipleOrderDatas(trackingNumbers);

  if (response instanceof UnknownError) {
    throw new Error("An unknown error occurred");
  }

  if (response instanceof NotFoundError) {
    throw new Error(`Could not find orders: ${trackingNumbers}`);
  }

  if (response instanceof HttpError) {
    throw new Error(`Unable to contact API. Error Code: ${response.errorCode}`);
  }

  return {
    orders: response,
    trackingNumbers: trackingNumbers,
  };
}

async function epodByReferenceAndAccount({
  params: { referenceNumber, accountNumber },
}) {
  console.log(referenceNumber, accountNumber);
  const response = await FetchPODDetailsByReference(
    accountNumber,
    referenceNumber
  );

  if (response instanceof UnknownError) {
    throw new Error("An unknown error occurred");
  }
  if (response instanceof NotFoundError) {
    throw new Error(`Could not find reference: ${referenceNumber}`);
  }

  if (response instanceof HttpError) {
    throw new Error(`Unable to contact API. Error Code: ${response.errorCode}`);
  }

  console.log(response);

  return response;
}

const childPaths = [
  {
    path: "cancel",
    element: <CancelOrderModal />,
  },
  {
    path: "sms",
    element: <SMSModal />,
  },
  {
    path: "opod",
    element: <PODOnDemandModal />,
  },
  {
    path: "epod/:postalCode?",
    element: <PODECommerceModal />,
  },
  {
    path: "deliveryTime",
    element: <DeliveryTimeModal />,
    loader: orderLoader,
  },
  {
    path: "deliveryInstructions",
    element: <DeliveryInstructionsModal />,
  },
  {
    path: "track/:postalCode?",
    element: <TrackDriverModal />,
    loader: orderLoader,
  },
  {
    path: "epodacct/:accountNumber",
    element: <PODModal />,
    loader: epodByReferenceAndAccount,
  },
];

const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "/",
        element: <TrackOrderFormPage />,
      },
      {
        path: "multipleOrders",
        element: <TrackingMultipleFormPage />,
      },
      {
        path: "orderNumber/:trackingNumber",
        element: <OrderDetailsPage />,
        loader: orderLoader,
        children: childPaths,
      },
      {
        path: "trackMultiple/:trackingNumbers",
        element: <MultipleOrderDetailsPage />,
        loader: multipleOrderLoader,
      },
      {
        path: "reference/:referenceNumber",
        element: <OrderDetailsPage />,
        loader: podByReference,
        children: childPaths,
      },
      {
        path: "*",
        element: <NotFoundPage />,
      },
    ],
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Suspense fallback={<LoadingAnimation />}>
      <I18nextProvider i18n={i18n}>
        <RouterProvider
          router={router}
          fallbackElement={<LoadingAnimation />}
        />
      </I18nextProvider>
    </Suspense>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
