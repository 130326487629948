import React from "react";

import Header from "./Header";
import Spinner from "react-bootstrap/Spinner";
import { Container, Row } from "react-bootstrap";
import { Trans } from "react-i18next";

function LoadingAnimation() {
  return (
    <>
      <Header />

      <Container fluid className="mt-5">
        <Row className="text-center">
          <p>
            <Trans i18nKey="Loading.processing">
              Processing, please wait.
            </Trans>
          </p>
        </Row>
        <Row className="d-flex justify-content-center">
          <Spinner animation="border" role="status">
            <span className="visually-hidden">
              <Trans i18nKey="Loading.loading">
                Loading...
              </Trans>
            </span>
          </Spinner>
        </Row>
        {/* <div className="container-fluid justify-content-center d-flex flex-column text-center"> */}
        <div></div>
        {/* </div> */}
      </Container>
    </>
  );
}

export default LoadingAnimation;
