
import { useTranslation, Trans } from 'react-i18next';
import React, { useCallback } from 'react';
import NavDropdown from 'react-bootstrap/NavDropdown';

export const LanguageSwitcherButton = () => {
    const { t, i18n: { language, changeLanguage } } = useTranslation();

    const handleLanguageSwitching = useCallback((language) => {
        changeLanguage(language);
    }, [changeLanguage])

    return (
        <NavDropdown title={t(`Language.${language}`)} id="nav-dropdown">
            <NavDropdown.Item eventKey="4.1" disabled={language === "enCA"}
                onClick={() => {
                    handleLanguageSwitching("enCA")
                }}>
                <Trans i18nKey="Language.en">English</Trans>
            </NavDropdown.Item>
            <NavDropdown.Item eventKey="4.4" disabled={language === "frCA"}
                onClick={() => {
                    handleLanguageSwitching("frCA")
                }}>
                <Trans i18nKey="Language.frCA">French</Trans>
            </NavDropdown.Item>
        </NavDropdown>
    )
}