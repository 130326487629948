import React, { useState, useEffect, useCallback, useRef } from "react";
import Modal from "react-bootstrap/Modal";
import trackDriverIcon from "../assets/icons/Green/APX_Transit Canada_80x80.png";
import {
  VerifyTrackDriver,
  HttpError,
  NotFoundError,
  UnknownError,
  TrackDriver,
} from "../functions/ApiCalls";
import {
  APIProvider,
  Map,
  AdvancedMarker,
  Pin,
  useMap,
  useMapsLibrary,
} from "@vis.gl/react-google-maps";
import { useLoaderData } from "react-router-dom";
import { useNavigate, useParams } from "react-router";

import carBlack from "../assets/car-black.svg";
import { useTranslation, Trans } from "react-i18next";

const leftOrRight = (driverCoordinates, deliveryCoordinates) => {
  if (driverCoordinates.lng < deliveryCoordinates.lng) {
    return "scaleX(1)";
  }

  return "scaleX(-1)";
};

const DriverRoute = ({
  pickupCoordinates,
  deliveryCoordinates,
  driverCoordinates,
}) => {
  const map = useMap();
  const routesLibrary = useMapsLibrary("routes");
  const [directionsService, setDirectionsService] = useState(undefined);
  const [directionsRenderer, setDirectionsRenderer] = useState(undefined);

  // Initialize directions service and renderer
  useEffect(() => {
    if (!routesLibrary || !map) return;
    setDirectionsService(new routesLibrary.DirectionsService());
    setDirectionsRenderer(
      new routesLibrary.DirectionsRenderer({
        map: map,
        suppressMarkers: true,
        preserveViewport: false,
      })
    );
  }, [routesLibrary, map]);

  // Use directions service
  useEffect(() => {
    const lookupDirections = async () => {
      if (!directionsService || !directionsRenderer) return;

      if (driverCoordinates === undefined) return;

      const response = await directionsService.route({
        origin: pickupCoordinates,
        destination: deliveryCoordinates,
        waypoints: [
          {
            location: driverCoordinates,
            stopover: false,
          },
        ],
        travelMode: "DRIVING",
      });

      directionsRenderer.setDirections(response);
    };

    lookupDirections();
  }, [
    directionsService,
    directionsRenderer,
    pickupCoordinates,
    deliveryCoordinates,
    driverCoordinates,
  ]);
};

const TrackDriverModal = () => {
  const { order, trackingNumber } = useLoaderData();
  const { postalCode } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { pickupCoordinates, deliveryCoordinates } = order.orderDetails;
  const [currentPage, setCurrentPage] = useState(1);
  const [accountNumber, setAccountNumber] = useState("");
  const [deliveryPostalCode, setDeliveryPostalCode] = useState("");
  const [verificationError, setVerificationError] = useState("");
  const [driverCoordinates, setDriverCoordinates] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const postalCodeRef = useRef();
  const center = {
    lat: (pickupCoordinates.lat + deliveryCoordinates.lat) / 2,
    lng: (pickupCoordinates.lng + deliveryCoordinates.lng) / 2,
  };

  const doVerification = useCallback(
    (pCode) => {
      const exec = async () => {
        setIsLoading(true);

        const response = await VerifyTrackDriver(
          trackingNumber,
          accountNumber,
          pCode
        );

        if (response instanceof HttpError) {
          setVerificationError(t("TrackDriverModal.fetchErrors.httpError"));
          setIsLoading(false);
          return;
        }

        if (response instanceof NotFoundError) {
          setVerificationError(t("TrackDriverModal.fetchErrors.notFoundError"));
          setIsLoading(false);
          return;
        }

        if (response instanceof UnknownError) {
          setVerificationError(t("TrackDriverModal.fetchErrors.unknownError"));
          setIsLoading(false);
          return;
        }

        setIsLoading(false);
        setVerificationError("");
        setAccountNumber(accountNumber);
        setDeliveryPostalCode(pCode);
        setCurrentPage(currentPage + 1);
      };
      exec();
    },
    [accountNumber, currentPage, t, trackingNumber]
  );

  useEffect(() => {
    const exec = async () => {
      postalCodeRef.current.value = postalCode;
      doVerification(postalCode);
    };
    if (postalCode && postalCodeRef.current) {
      exec();
    }
    // doVerification was intentionally left out of the dependency array to prevent an infinite loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [postalCode]);

  const verifyPage1 = useCallback(
    async (event) => {
      event.preventDefault();

      const formData = new FormData(event.target);
      const accountNumber = formData.get("accountNumber").trim();
      const destinationPostalCode = formData
        .get("destinationPostalCode")
        .trim();

      if (accountNumber === "" && destinationPostalCode === "") {
        setVerificationError(t("TrackDriverModal.verifyErrors.unknownError"));
        return;
      }

      doVerification(destinationPostalCode);
    },
    [doVerification, t]
  );

  const fetchDriverLocation = useCallback(() => {
    const fetchDriver = async () => {
      const response = await TrackDriver(
        trackingNumber,
        accountNumber,
        deliveryPostalCode
      );

      if (response instanceof HttpError) {
        setVerificationError(t("TrackDriverModal.fetchErrors.httpError"));
        return;
      }

      if (response instanceof NotFoundError) {
        setVerificationError(t("TrackDriverModal.fetchErrors.notFoundError"));
        return;
      }

      if (response instanceof UnknownError) {
        setVerificationError(t("TrackDriverModal.fetchErrors.unknownError"));
        return;
      }

      if (!response.driverCoordinates) {
        setVerificationError(t("TrackDriverModal.fetchErrors.noDriver"));
        return;
      }

      setDriverCoordinates(response.driverCoordinates);
    };

    fetchDriver();
  }, [accountNumber, deliveryPostalCode, t, trackingNumber]);

  useEffect(() => {
    if (currentPage === 2) {
      fetchDriverLocation();
    }
  }, [currentPage, fetchDriverLocation]);

  const handleTrigger = useCallback(() => {
    fetchDriverLocation();
  }, [fetchDriverLocation]);

  const onClose = () => {
    let path = "..";
    if (postalCode) {
      path = "../..";
    }
    navigate(path, { relative: "path" });
  };

  return (
    <Modal
      show={true}
      onHide={onClose}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <img
            src={trackDriverIcon}
            alt="sms"
            className="me-2"
            height="40px"
          ></img>
          <Trans i18nKey="TrackDriverModal.title">Track Driver</Trans>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {currentPage === 1 && (
          <form onSubmit={verifyPage1}>
            <fieldset
              disabled={isLoading}
              className="d-flex justify-content-center flex-column"
            >
              <p>
                <Trans i18nKey="TrackDriverModal.prompt">
                  Please enter your account number&nbsp;
                  <b>
                    <u>or</u>
                  </b>
                  &nbsp; postal code so we can verify you.
                </Trans>
              </p>

              <div className="mb-3">
                <label htmlFor="accountNumber" className="form-label">
                  <Trans i18nKey="Generic.accountNumber">Account Number</Trans>
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="accountNumber"
                  name="accountNumber"
                  aria-describedby="accountNumber"
                  placeholder="200211283"
                  autoFocus={true}
                />
              </div>

              <div className="mb-3">
                <label htmlFor="destinationPostalCode" className="form-label">
                  <Trans i18nKey="Generic.destinationPostalCode">
                    Destination Postal Code
                  </Trans>
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="destinationPostalCode"
                  name="destinationPostalCode"
                  aria-describedby="destinationPostalCode"
                  placeholder="L4W5J2"
                  maxLength="7"
                  ref={postalCodeRef}
                />
              </div>

              {verificationError && (
                <b>
                  <p className="text-danger text-center">{verificationError}</p>
                </b>
              )}
              <button className="btn btn-primary" type="submit">
                {isLoading ? "..." : t("Generic.continue")}
              </button>
            </fieldset>
          </form>
        )}

        {currentPage === 2 && (
          <div className="d-flex justify-content-center flex-column">
            {verificationError && (
              <b>
                <p className="text-danger text-center">{verificationError}</p>
              </b>
            )}
            <APIProvider apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}>
              <Map
                zoom={10}
                center={center}
                gestureHandling={"greedy"}
                disableDefaultUI={true}
                style={{ height: "400px", width: "100%" }}
                mapId={process.env.REACT_APP_GOOGLE_MAPS_ID}
                id="myMap"
              >
                <AdvancedMarker
                  position={pickupCoordinates}
                  title={t("TrackDriverModal.pickupLocation")}
                >
                  <Pin
                    background={"#e25d6e"}
                    borderColor={"#1e89a1"}
                    glyphColor={"#0f677a"}
                  ></Pin>
                </AdvancedMarker>

                <AdvancedMarker
                  position={deliveryCoordinates}
                  title={t("TrackDriverModal.deliveryLocation")}
                >
                  <Pin
                    background={"#59ba39"}
                    borderColor={"#1e89a1"}
                    glyphColor={"#0f677a"}
                  ></Pin>
                </AdvancedMarker>

                {driverCoordinates && (
                  <AdvancedMarker
                    position={driverCoordinates}
                    title={t("TrackDriverModal.driverLocation")}
                  >
                    <img
                      src={carBlack}
                      alt="car"
                      height="30px"
                      style={{
                        transform: leftOrRight(
                          driverCoordinates,
                          deliveryCoordinates
                        ),
                      }}
                    ></img>
                  </AdvancedMarker>
                )}
                <DriverRoute
                  pickupCoordinates={pickupCoordinates}
                  deliveryCoordinates={deliveryCoordinates}
                  driverCoordinates={driverCoordinates}
                />
              </Map>
              <DriverRefresh triggerRefresh={handleTrigger} />
            </APIProvider>
            <br />
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
};

const refreshRate = 30;

const DriverRefresh = ({ triggerRefresh }) => {
  const [count, setSeconds] = useState(refreshRate);

  const { t } = useTranslation();

  useEffect(() => {
    const timer = setInterval(() => {
      let nextSeconds = count - 1;

      if (nextSeconds === 0) {
        nextSeconds = refreshRate;

        triggerRefresh();
      }

      setSeconds(nextSeconds);
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  });

  return <>{t("TrackDriverModal.locationUpdatingIn", { count })}</>;
};

export default TrackDriverModal;
