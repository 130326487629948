const OrderStatusMap = {
    1: 'Open',
    2: 'Assigned',
    3: 'Dispatched',
    4: 'PickedUp',
    5: 'Delivered',
    6: 'InStorage',
    7: 'Cancelled',
    9: 'Finalized',
    10: 'Air',
    11: 'InProgress',
    12: 'SwapCompleted',
    13: 'OnHold',
    14: 'ReturntoOrigin',
    15: 'ASN',
    16: 'ScannedatWarehouse',
    17: 'Shortage',
    18: 'Overage',
    19: 'Overcap',
    20: 'ExceptionInvalidAddress',
    21: 'AddressCorrected',
    22: 'AddressrequiresCorrection',
    23: 'UndeliverableDeliveryException',
    24: 'RedirectedtoPUDO',
    25: 'ExceptionIncorrectSortFacility',
    26: 'ExceptionFSANotServiced',
    27: 'ReturnsShortage',
    28: 'ReScannedAtWarehouse',
    29: 'PackageLost',
    30: 'ProcessedAtWarehouse',
    31: 'DepartedWarehouse',
    32: 'ArrivedAtLocalWarehouse',
    33: 'PickFromPickupLocation',
}

export const OrderStatus = {
    'Open': 1,
    'Assigned': 2,
    'Dispatched': 3,
    'PickedUp': 4,
    'Delivered': 5,
    'InStorage': 6,
    'Cancelled': 7,
    'Finalized': 9,
    'Air': 10,
    'InProgress': 11,
    'SwapCompleted': 12,
    'OnHold': 13,
    'ReturntoOrigin': 14,
    'ASN': 15,
    'ScannedatWarehouse': 16,
    'Shortage': 17,
    'Overage': 18,
    'Overcap': 19,
    'ExceptionInvalidAddress': 20,
    'AddressCorrected': 21,
    'AddressrequiresCorrection': 22,
    'UndeliverableDeliveryException': 23,
    'RedirectedtoPUDO': 24,
    'ExceptionIncorrectSortFacility': 25,
    'ExceptionFSANotServiced': 26,
    'ReturnsShortage': 27,
    'ReScannedAtWarehouse': 28,
    'PackageLost': 29,
    'ProcessedAtWarehouse': 30,
    'DepartedWarehouse': 31,
    'ArrivedAtLocalWarehouse': 32,
    'PickFromPickupLocation': 33,
}

export const mapOrderStatusId = (orderStatusId) => {
    return OrderStatusMap[orderStatusId];
}