import React from "react";
import { Row, Col } from "react-bootstrap";
import proofIcon from "../assets/icons/Green/APX_Delivered._80x80.png";
import cancelIcon from "../assets/icons/New/Cancel.png";
import smsIcon from "../assets/icons/New/SMS.png";
import deliveryTimeIcon from "../assets/icons/New/DeliveryTime.png";
import trackDriverIcon from "../assets/icons/Green/APX_Transit Canada_80x80.png";
import deliveryInstructionsIcon from "../assets/icons/New/DeliveryInstructions.png";

import DeliveryActionButton from "./DeliveryActionButton";
import { Outlet } from "react-router";

function DeliveryActionButtons({ data }) {
  const isEcommerce = data?.orderDetails.isECommerce;

  const smsButton = data?.screenDetails?.smsButton;
  const podButton = data?.screenDetails.podButton;
  const trackDriverButton = data?.screenDetails.trackDriverButton;
  const deliveryTimeButton = data?.screenDetails.setDeliveryTimeButton;
  const deliveryInstructionButton =
    data?.screenDetails.addDeliveryInstructionsButton;
  const cancelButton = data?.screenDetails?.cancelButton;

  return (
    <Row className="ms-0 me-0">
      <Col xs={12} className="d-flex flex-wrap justify-content-center">
        <DeliveryActionButton
          enabled={cancelButton.enabled}
          path={"cancel"}
          icon={cancelIcon}
          caption={cancelButton.caption}
          visible={cancelButton?.visible}
        />

        <DeliveryActionButton
          enabled={smsButton.enabled}
          path={"sms"}
          icon={smsIcon}
          caption={smsButton.caption}
          visible={smsButton?.visible}
        />

        <DeliveryActionButton
          enabled={podButton.enabled}
          path={isEcommerce ? "epod" : "opod"}
          icon={proofIcon}
          caption={podButton.caption}
          visible={podButton?.visible}
        />

        <DeliveryActionButton
          enabled={trackDriverButton.enabled}
          path={"track"}
          icon={trackDriverIcon}
          caption={trackDriverButton.caption}
          visible={trackDriverButton?.visible}
        />

        <DeliveryActionButton
          enabled={deliveryTimeButton.enabled}
          path={"deliveryTime"}
          icon={deliveryTimeIcon}
          caption={deliveryTimeButton.caption}
          visible={deliveryTimeButton?.visible}
        />

        <DeliveryActionButton
          enabled={deliveryInstructionButton.enabled}
          path={"deliveryInstructions"}
          icon={deliveryInstructionsIcon}
          caption={deliveryInstructionButton.caption}
          visible={deliveryInstructionButton?.visible}
        />

        <Outlet />
      </Col>
    </Row>
  );
}

export default DeliveryActionButtons;
