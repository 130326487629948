import React, { useState, useCallback } from "react";
import "react-datepicker/dist/react-datepicker.css";
import Modal from "react-bootstrap/Modal";
import deliveryIcon from "../assets/icons/New/DeliveryTime.png";

import {
  HttpError,
  NotFoundError,
  SendDeliveryTime,
  UnknownError,
  VerifyDeliveryTime,
} from "../functions/ApiCalls";
import { useLoaderData } from "react-router-dom";
import { useNavigate } from "react-router";
import { useTranslation, Trans } from "react-i18next";

const DeliveryDateModal = () => {
  const { data, trackingNumber } = useLoaderData();
  const { t } = useTranslation();

  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedTimeSlot, setSelectedTimeSlot] = useState();

  const [destinationPostalCode, setDestinationPostalCode] = useState("");
  const [verificationError, setVerificationError] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const verifyPage1 = useCallback(
    async (event) => {
      event.preventDefault();
      const formData = new FormData(event.target);
      const destinationPostalCode = formData
        .get("destinationPostalCode")
        .trim();

      if (destinationPostalCode === "") {
        setVerificationError(t("DeliveryTimeModal.verifyErrors.destinationPostalCodeEmpty"));
        return;
      }
      setIsLoading(true);

      const result = await VerifyDeliveryTime(
        trackingNumber,
        destinationPostalCode
      );

      if (result instanceof HttpError) {
        setVerificationError(t("DeliveryTimeModal.verifyErrors.httpError"));
        setIsLoading(false);
        return;
      }

      if (result instanceof NotFoundError) {
        setVerificationError(t("DeliveryTimeModal.verifyErrors.notFoundError"));
        setIsLoading(false);
        return;
      }

      if (result instanceof UnknownError) {
        setVerificationError(t("DeliveryTimeModal.verifyErrors.unknownError"));
        setIsLoading(false);
        return;
      }

      setIsLoading(false);
      setDestinationPostalCode(destinationPostalCode);
      setVerificationError("");
      setCurrentPage(currentPage + 1);
    },
    [currentPage, t, trackingNumber]
  );

  const previousPage = () => {
    setCurrentPage(currentPage - 1);
  };

  const verifyPage2 = useCallback(
    async (event) => {
      event.preventDefault();
      const formData = new FormData(event.target);
      const timeSlot = formData.get("timeSlot").trim();

      if (timeSlot === "") {
        setVerificationError(t("DeliveryTimeModal.timeSlotErrors.timeSlotEmpty"));
        return;
      }

      setSelectedTimeSlot(timeSlot);
      setCurrentPage(currentPage + 1);
    },
    [currentPage, t]
  );

  const submitData = useCallback(
    async (event) => {
      event.preventDefault();

      setIsLoading(true);
      const result = await SendDeliveryTime(
        trackingNumber,
        destinationPostalCode,
        selectedTimeSlot
      );

      if (result instanceof HttpError) {
        setVerificationError(t("DeliveryTimeModal.submitErrors.httpError"));
        setIsLoading(false);
        return;
      }

      if (result instanceof NotFoundError) {
        setVerificationError(t("DeliveryTimeModal.submitErrors.notFoundError"));
        setIsLoading(false);
        return;
      }

      if (result instanceof UnknownError) {
        setVerificationError(t("DeliveryTimeModal.submitErrors.unknownError"));
        setIsLoading(false);
        return;
      }

      window.location.reload();
    },
    [destinationPostalCode, selectedTimeSlot, t, trackingNumber]
  );

  const onClose = () => {
    navigate("..", { relative: "path" });
  };

  return (
    <>
      <Modal
        show={true}
        onHide={onClose}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <img
              src={deliveryIcon}
              alt="delivery time"
              className="me-2"
              height="40px"
            ></img>
            <Trans i18nKey="DeliveryTimeModal.title">
              Set Delivery Time
            </Trans>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {currentPage === 1 && (
            <form onSubmit={verifyPage1}>
              <fieldset
                disabled={isLoading}
                className="d-flex justify-content-center flex-column"
              >
                <p>
                  <Trans i18nKey="DeliveryTimeModal.prompt">
                    Please enter your postal code so we can verify you.
                  </Trans>
                </p>
                <div className="mb-3">
                  <label htmlFor="destinationPostalCode" className="form-label">
                    <Trans i18nKey="DeliveryTimeModal.destinationPostalCode">
                      Destination Postal Code
                    </Trans>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="destinationPostalCode"
                    name="destinationPostalCode"
                    aria-describedby="destinationPostalCode"
                    placeholder="L4W5J2"
                    maxLength="7"
                    autoFocus={true}
                  />
                </div>

                {verificationError && (
                  <b>
                    <p className="text-danger text-center">
                      {verificationError}
                    </p>
                  </b>
                )}

                <button className="btn btn-primary" type="submit">
                  {isLoading ? "..." : t("Generic.continue")}
                </button>
              </fieldset>
            </form>
          )}
          {currentPage === 2 && (
            <form onSubmit={verifyPage2}>
              <fieldset
                disabled={isLoading}
                className="d-flex justify-content-center flex-column"
              >
                <p className="mb-3">
                  <Trans i18nKey="DeliveryTimeModal.selectTimeSlot">
                    Please select a time slot that works best for you.
                  </Trans>
                </p>
                {data?.screenDetails?.deliveryTimeOptions?.map((option) => (
                  <div className="form-check" key={option.optionNo}>
                    <input
                      className="form-check-input"
                      type="radio"
                      name="timeSlot"
                      id={option.optionNo}
                      value={option.option}
                    />
                    <label
                      className="form-check-label"
                      htmlFor={option.optionNo}
                    >
                      {option.option}
                    </label>
                  </div>
                ))}
                {verificationError && (
                  <b>
                    <p className="text-danger text-center">
                      {verificationError}
                    </p>
                  </b>
                )}

                <button className="btn btn-primary mt-5" type="submit">
                  {isLoading ? "..." : t("Generic.continue")}
                </button>
              </fieldset>
            </form>
          )}
          {currentPage === 3 && (
            <form onSubmit={submitData}>
              <fieldset
                disabled={isLoading}
                className="d-flex justify-content-center flex-column"
              >
                <p>
                  <Trans i18nKey="DeliveryTimeModal.confirm">
                    Confirm your Delivery Time Preference
                  </Trans>
                </p>
                <p className="mb-2">
                  <Trans i18nKey="DeliveryTimeModal.selectedTime">
                    Selected Time: <b>{{ selectedTimeSlot }}</b>
                  </Trans>
                </p>
                <p className="mb-1">
                  <Trans i18nKey="DeliveryTimeModal.notes">
                    We will do our best to deliver during the selected time
                    window.
                  </Trans>
                </p>

                {verificationError && (
                  <b>
                    <p className="text-danger text-center">
                      {verificationError}
                    </p>
                  </b>
                )}

                <button
                  className="btn btn-secondary mt-5"
                  type="button"
                  onClick={previousPage}
                >
                  <Trans i18nKey="Generic.back">
                    Back
                  </Trans>
                </button>
                <button className="btn btn-primary mt-1" type="submit">
                  {isLoading ? "..." : t("Generic.continue")}
                </button>
              </fieldset>
            </form>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default DeliveryDateModal;
