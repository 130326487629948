import React, { useState, useEffect, useCallback } from "react";

import moon from "../assets/moon.png";
import sun from "../assets/sun.png";

function Theme() {
  const prefersDarkMode = () => {
    return (
      window.matchMedia &&
      window.matchMedia("(prefers-color-scheme: dark)").matches
    );
  };

  const [isDarkTheme, setIsDarkTheme] = useState(prefersDarkMode());

  const handleClick = () => {
    setIsDarkTheme(!isDarkTheme);
  };

  const updateTheme = useCallback(() => {
    const root = document.documentElement;
    const nextTheme = isDarkTheme ? "dark" : "light";

    root.setAttribute("data-bs-theme", nextTheme);
  }, [isDarkTheme]);

  useEffect(() => {
    updateTheme();
  }, [isDarkTheme, updateTheme]);

  return (
    <div
      className="fixed-bottom d-flex justify-content-end"
      style={{ pointerEvents: "none" }}
    >
      <img
        src={isDarkTheme ? sun : moon}
        onClick={handleClick}
        title="Change theme"
        alt="theme"
        width="50px"
        height="50px"
        className="m-3"
        style={{ pointerEvents: "auto" }}
      />
    </div>
  );
}

export default Theme;
