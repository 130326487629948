import { Row } from "react-bootstrap";
import { Trans } from "react-i18next";
function ECommerceDeliveryOptions({ data }) {
  const {
    orderDetails: { deliveryInstructions },
  } = data;
  return (
    <Row className="mb-3 ms-0 me-0">
      <h2>
        <Trans i18nKey="EcommerceDeliveryOptions.header">
          Delivery Options
        </Trans>
      </h2>
      {/* <p>
        Delivery Time Preference: <b> {data.orderDetails.deliveryTime}</b>
      </p> */}
      <p>
        <Trans i18nKey="EcommerceDeliveryOptions.instructions">
          Delivery Instructions: <b> {{ deliveryInstructions }}</b>
        </Trans>
      </p>
    </Row>
  );
}

export default ECommerceDeliveryOptions;
