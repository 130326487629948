import { useNavigate } from "react-router";
import "../css/DeliveryActionButton.css";

function DeliveryActionButton({ enabled, path, icon, caption, visible }) {
  const navigate = useNavigate();
  if (!visible) {
    return null;
  }

  const handleClick = (e) => {
    e.preventDefault();
    navigate(path);
  };

  return (
    <div className="delivery-action-button mb-3 me-3">
      <button
        disabled={!enabled}
        onClick={handleClick}
        type="button"
        className="btn btn-secondary d-inline-flex flex-column align-items-center justify-content-around"
      >
        <img src={icon} alt="" />
        {caption}
      </button>
    </div>
  );
}

export default DeliveryActionButton;
