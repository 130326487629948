import React from "react";
import notFoundIcon from "../assets/icons/Red/APX_Delivery Exception_80x80.png";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Trans } from "react-i18next";

const ItemNotFoundModal = ({ isOpen, onClose }) => {
  return (
    <Modal
      show={isOpen}
      onHide={onClose}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <img
            src={notFoundIcon}
            alt="not found"
            className="me-2"
            height="40px"
          ></img>
          <Trans i18nKey="NotFoundModal.title">
            Item Not Found
          </Trans>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <p>
            <Trans i18nKey="NotFoundModal.details">
              We don’t have information for the item you entered due to one of the
              following reasons.
            </Trans>
          </p>
          <ul>
            <li>
              <Trans i18nKey="NotFoundModal.reason1">
                The number you entered is incorrect. Please review it and try
                again. Find out more about tracking, delivery notice card, and
                reference numbers.
              </Trans>
            </li>
            <li>
              <Trans i18nKey="NotFoundModal.reason2">
                We haven’t received this item from the sender.
              </Trans>
            </li>
            <li>
              <Trans i18nKey="NotFoundModal.reason3">
                The sender shipped this item through a service that doesn’t
                include tracking.
              </Trans>
            </li>
            <li>
              <Trans i18nKey="NotFoundModal.reason4">
                This item was shipped over a year ago.
              </Trans>
            </li>
          </ul>
          <br></br>
          <p className="itemNotFound-extraContent">
            <Trans i18nKey="NotFoundModal.notes2">
              These results reflect the most recent updates in our system. Our
              customer service representatives have access to the same information
              presented here and are unable to provide further details.
            </Trans>
          </p>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onClose}>
          <Trans i18nKey="Generic.close">
            Close
          </Trans>
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ItemNotFoundModal;
