import React, { useState, useCallback } from "react";
import "react-datepicker/dist/react-datepicker.css";
import Modal from "react-bootstrap/Modal";
import deliveryInstructionsIcon from "../assets/icons/New/DeliveryInstructions.png";
import { useTranslation, Trans } from "react-i18next";

import {
  VerifyDeliveryInstructions,
  HttpError,
  NotFoundError,
  UnknownError,
  SendDeliveryInstructions,
} from "../functions/ApiCalls";
import { useNavigate, useParams } from "react-router";

const DeliveryInstructionsModal = () => {
  const { trackingNumber } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [currentPage, setCurrentPage] = useState(1);
  const [note, setNote] = useState("");
  const [deliveryPostalCode, setDeliveryPostalCode] = useState("");
  const [verificationError, setVerificationError] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const verifyPage1 = useCallback(
    async (event) => {
      event.preventDefault();

      const formData = new FormData(event.target);
      const formDestinationPostalCode = formData
        .get("destinationPostalCode")
        .trim();

      if (formDestinationPostalCode === "") {
        setVerificationError(t("DeliveryInstructionModal.verifyErrors.destinationPostalCodeEmpty"));
        return;
      }
      setIsLoading(true);

      const result = await VerifyDeliveryInstructions(
        trackingNumber,
        formDestinationPostalCode
      );

      if (result instanceof HttpError) {
        setVerificationError(
          t("DeliveryInstructionModal.verifyErrors.httpError")
        );
        setIsLoading(false);
        return;
      }

      if (result instanceof NotFoundError) {
        setVerificationError(t("DeliveryInstructionModal.verifyErrors.notFoundError"));
        setIsLoading(false);
        return;
      }

      if (result instanceof UnknownError) {
        setVerificationError(
          t("DeliveryInstructionModal.verifyErrors.unknownError")
        );
        setIsLoading(false);
        return;
      }

      setIsLoading(false);
      setVerificationError("");
      setDeliveryPostalCode(formDestinationPostalCode);
      setCurrentPage(currentPage + 1);
    },
    [currentPage, t, trackingNumber]
  );

  const verifyPage2 = useCallback(
    (event) => {
      event.preventDefault();
      const formData = new FormData(event.target);
      const formNotes = formData.get("note").trim();

      if (formNotes === "") {
        setVerificationError(t("DeliveryInstructionModal.notesErrors.notesEmpty"));
        return;
      }

      setVerificationError("");
      setCurrentPage(currentPage + 1);
      setNote(formNotes);
    },
    [currentPage, t]
  );

  const submitData = useCallback(
    async (event) => {
      event.preventDefault();
      setIsLoading(true);
      const result = await SendDeliveryInstructions(
        trackingNumber,
        deliveryPostalCode,
        note
      );

      if (result instanceof HttpError) {
        setVerificationError(
          t("DeliveryInstructionModal.submitErrors.httpError")
        );
        setIsLoading(false);
        return;
      }

      if (result instanceof NotFoundError) {
        t("DeliveryInstructionModal.submitErrors.notFoundError")
        setIsLoading(false);
        return;
      }

      if (result instanceof UnknownError) {
        t("DeliveryInstructionModal.submitErrors.unknownError")
        setIsLoading(false);
        return;
      }

      window.location.reload();
    },
    [deliveryPostalCode, note, t, trackingNumber]
  );

  const previousPage = () => {
    setCurrentPage(currentPage - 1);
  };

  const handleOnHide = () => {
    navigate("..", { relative: "path" });
  };

  return (
    <>
      <Modal
        show={true}
        onHide={handleOnHide}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <img
              src={deliveryInstructionsIcon}
              alt="delivery instructions"
              className="me-2"
              height="40px"
            ></img>
            <Trans i18nKey="DeliveryInstructionModal.title">Add Delivery Instructions</Trans>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {currentPage === 1 && (
            <form onSubmit={verifyPage1}>
              <fieldset
                disabled={isLoading}
                className="d-flex justify-content-center flex-column"
              >
                <p>
                  <Trans i18nKey="DeliveryInstructionModal.enterPostalCode">
                    Please enter your postal code so we can verify you.
                  </Trans>
                </p>
                <div className="mb-3">
                  <label htmlFor="destinationPostalCode" className="form-label">
                    <Trans i18nKey="DeliveryInstructionModal.destinationPostalCode">Destination Postal Code</Trans>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="destinationPostalCode"
                    name="destinationPostalCode"
                    aria-describedby="destinationPostalCode"
                    placeholder="L4W5J2"
                    maxLength="7"
                    required={true}
                    autoFocus={true}
                  />
                </div>

                {verificationError && (
                  <b>
                    <p className="text-danger text-center">
                      {verificationError}
                    </p>
                  </b>
                )}

                <button className="btn btn-primary" type="submit">
                  {isLoading ? "..." : t("Generic.continue")}
                </button>
              </fieldset>
            </form>
          )}
          {currentPage === 2 && (
            <form onSubmit={verifyPage2}>
              <fieldset
                disabled={isLoading}
                className="d-flex justify-content-center flex-column"
              >
                <p>
                  <Trans i18nKey="DeliveryInstructionModal.addNotes">
                    To make sure you get your package, please give us any
                    additional information to make this delivery successful!
                    <br />
                    You can add an entry code, specific door or anything special
                    about your address.
                  </Trans>
                </p>
                <div className="mb-3">
                  <textarea
                    rows={5}
                    cols={30}
                    placeholder=""
                    style={{ height: "60px", resize: "none" }}
                    type="text"
                    className="form-control"
                    autoComplete="off"
                    id="note"
                    name="note"
                    aria-describedby="note"
                    required={true}
                    autoFocus={true}
                    maxLength="150"
                  />
                </div>

                {verificationError && (
                  <b>
                    <p className="text-danger text-center">
                      {verificationError}
                    </p>
                  </b>
                )}

                <button className="btn btn-primary" type="submit">
                  {isLoading ? "..." : t("Generic.continue")}
                </button>
              </fieldset>
            </form>
          )}
          {currentPage === 3 && (
            <form onSubmit={submitData}>
              <fieldset
                disabled={isLoading}
                className="d-flex justify-content-center flex-column"
              >
                <p>
                  <b>
                    <Trans i18nKey="DeliveryInstructionModal.areYouSure">
                      Are you sure you would like to submit?
                    </Trans>
                  </b>
                </p>
                <p>
                  <Trans i18nKey="DeliveryInstructionModal.onceYouSubmit">
                    Once you submit, you will not be able to edit the instruction.
                  </Trans>
                </p>
                <div className="note-section">
                  <p className="note-label">
                    <Trans i18nKey="DeliveryInstructionModal.notesPrompt">
                      <b>Notes:</b> {{ note }}
                    </Trans>
                  </p>
                </div>
                {verificationError && (
                  <p style={{ color: "red" }}>{verificationError}</p>
                )}
                <button
                  className="btn btn-secondary mt-3 mb-1"
                  onClick={previousPage}
                >
                  <Trans i18nKey="Generic.goBack">
                    Go Back
                  </Trans>
                </button>
                <button className="btn btn-primary" type="submit">
                  {isLoading ? "..." : t("DeliveryInstructionModal.submitInstructions")}
                </button>
              </fieldset>
            </form>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default DeliveryInstructionsModal;
