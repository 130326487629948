import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { format as formatDate, isDate, parse } from "date-fns";
import { enCA, frCA } from "date-fns/locale";
import enTranslation from "./assets/locales/enCA/translation.json";
import frTranslation from "./assets/locales/frCA/translation.json";

const locales = { en: enCA, fr: frCA };

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: false,
    resources: {
      en: {
        translation: enTranslation,
      },
      fr: {
        translation: frTranslation,
      },
    },
    fallbackLng: "enCA",
    interpolation: {
      escapeValue: false,
      format: (value, format, lng) => {
        try {
          const parsed = parse(value, "yyyy-MM-dd'T'HH:mm:ss", new Date());
          if (isDate(parsed)) {
            const locale = locales[lng];
            return formatDate(parsed, format, { locale });
          }
        } catch (e) {
          return value;
        }
      },
    },
  });

export default i18n;
